import { navigate } from 'gatsby'
import React, { useEffect } from 'react'

const NotFound = () => {
  useEffect(() => {
    navigate('/')
  })

  return <>404</>
}

export default NotFound
